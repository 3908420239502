<template>
  <ModalCreateProperty :showModal="showCreate" @close="showCreate = false" @save="addProperty" @error="error"></ModalCreateProperty>
  <div class="row w-100 m-0 text-start">
    <div class="col-12">
      <h2>Properties</h2>
    </div>
    <div class="col-12">
      <button class="btn btn-info btn-sm" @click="showCreate = true"><i class="fas fa-plus me-2"></i>New Property</button>
    </div>

    <div class="col-12 mt-4">
      <DataTable
        :value="properties"
        responsiveLayout="scroll"
        :paginator="true"
        :alwaysShowPaginator="false"
        :rows="numRows"
        removableSort
        v-model:filters="filters"
        :loading="loading"
        ref="dt"
        dataKey="id"
        editMode="row"
        v-model:editingRows="editingRows"
        @row-edit-save="onRowEditSave"
      >
        <template #header>
          <div class="row justify-content-between">
            <div class="col-3">
              <div class="d-flex align-items-center">
                <p class="text-start m-0 me-3">Show</p>
                <select class="form-select" v-model="numRows" style="width: 80px">
                  <option :value="10">10</option>
                  <option :value="20">20</option>
                  <option :value="50">50</option>
                </select>
              </div>
            </div>
            <div class="col-4">
              <input type="text" class="form-control" placeholder="Search" v-model="filters['global'].value" />
            </div>
          </div>
        </template>
        <Column field="name" header="Name" :sortable="true">
          <template #editor="{ data, field }">
            <input class="form-control" v-model="data[field]" />
          </template>
        </Column>

        <Column field="mls" header="MLS" :sortable="true">
          <template #editor="{ data, field }">
            <input class="form-control" v-model="data[field]" />
          </template>
        </Column>

        <Column field="description" header="Description" :sortable="true">
          <template #editor="{ data, field }">
            <input class="form-control" v-model="data[field]" />
          </template>
        </Column>

        <Column field="address" header="Address" :sortable="true" style="min-width: 20rem">
          <template #editor="{ data, field }">
            <input class="form-control" v-model="data[field]" />
          </template>
        </Column>

        <Column field="city" header="City" :sortable="true">
          <template #editor="{ data, field }">
            <input class="form-control" v-model="data[field]" />
          </template>
        </Column>

        <Column field="state.name" header="State" :sortable="true">
          <template #editor="{ data }">
            <DropdownStates :state="data.state" @selectState="selectState" :element="data"></DropdownStates>
          </template>
        </Column>

        <Column field="status.name" header="State" :sortable="true">
          <template #editor="{ data }">
            <select class="form-select" v-model="data.status_id">
              <option :value="`${Number('1')}`">Sold</option>
              <option :value="`${Number('2')}`">Available</option>
            </select>
          </template>
        </Column>

        <Column :rowEditor="true"></Column>
        <Column>
          <template #body="{ data }">
            <button class="btn" @click="deleteProperty(data)"><i class="pi pi-trash"></i></button>
          </template>
        </Column>
        <template #footer>
          <div class="d-flex justify-content-end">
            <button class="btn btn-primary" @click="exportCSV">Export CSV</button>
          </div>
        </template>
        <template #loading> <p class="text-center">Loading records, please wait...</p> </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import ModalCreateProperty from './components/ModalCreateProperty.vue'
import { computed, ref } from '@vue/reactivity'
import { onMounted, watch } from '@vue/runtime-core'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Swal from 'sweetalert2/src/sweetalert2'

import PropertyService from '../../services/property.service'
import { useDatatable } from '../../hooks/useDatatable'
import { useStore } from 'vuex'

import DropdownStates from '../../components/dropdowns/DropdownStates.vue'
import { useToast } from 'primevue/usetoast'

export default {
  components: {
    DataTable,
    Column,
    ModalCreateProperty,
    DropdownStates
  },
  setup() {
    const store = useStore()
    const role = computed(() => store.state.selectedRole)
    const showCreate = ref(false)
    const properties = ref([])
    const toast = useToast()
    const { loading, filters, numRows, dt, exportCSV, editingRows } = useDatatable()

    onMounted(() => {
      fetchProperties()
    })

    const fetchProperties = async () => {
      loading.value = true
      properties.value = await PropertyService.fetchProperties()
      loading.value = false
    }

    const addProperty = (property) => {
      properties.value.push(property)
      showCreate.value = false
      toast.add({ severity: 'success', summary: 'Property created!', detail:'', life: 3000 })
    }

    const error = () => {
      toast.add({ severity: 'error', summary: 'Something went wrong', detail:'Unable to create a property', life: 3000 })
    }

    const selectState = (state, element) => {
      for( let i in properties.value){
        if(properties.value[i].id === element.id){
          properties.value[i].state = state;
          properties.value[i].state_id = state.id;
          break;
        }
      }
    }

    const deleteProperty = (property) => {
      Swal.fire({
        title: `Are you sure to eliminate ${property.name}?`,
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await PropertyService.deleteProperty(property.id)
          properties.value = properties.value.filter((c) => c.id != property.id)
          Swal.fire('Deleted!', 'Property has been deleted.', 'success')
        }
      })
    }

    const onRowEditSave = async (event) => {
      const { newData } = event
      let property = { ...newData }
      for(let i in properties.value){
        if(properties.value[i].id === property.id){
          property.state_id = properties.value[i].state_id
          // property.status_id = parseInt(property.status_id)
          break;
        }
      }
      const updated = await PropertyService.updateProperty(property.id, property)
      properties.value = properties.value.map((c) => (c.id == updated.id ? updated : c))
    }

    return {
      properties,
      showCreate,
      loading,
      filters,
      numRows,
      dt,
      exportCSV,
      editingRows,
      error,
      onRowEditSave,
      addProperty,
      deleteProperty,
      selectState
    }
  },
}
</script>
