<template>
  <Dialog
    v-model:visible="showModal"
    :modal="true"
    icon="pi pi-external-link"
    position="center"
    :style="{ width: '60vw' }"
    @hide="$emit('close')"
    header="New Property"
  >
    <div class="row">
      <div class="col-12">
        <h5>Property information</h5>
      </div>
      <div class="row mt-4" >
        <div class="col-6">
          <div class="propertyForm-group">
            <label for="">Property Name</label>
            <input type="text" class="form-control"
              :class="{'is-invalid': v.name.$error}"
              v-model="v.name.$model"
            />
            <p class="invalid-feedback d-block m-0">{{ v.name.$errors[0]?.$message }}</p>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label for=""># MLS</label>
            <input type="text" class="form-control" v-model="propertyForm.mls" >
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label for="">Status</label>
            <select class="form-select" v-model="propertyForm.status_id">
              <option :value="`${Number('1')}`">Sold</option>
              <option :value="`${Number('2')}`">Available</option>
            </select>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Description</label>
            <input type="text" class="form-control" v-model="propertyForm.description" >
          </div>
        </div>

        <div class="col-6">
          <div class="form-group">
            <label for="">State</label>
            <DropdownStates :state="propertyForm.state" @selectState="selectState"></DropdownStates>
            <p class="invalid-feedback d-block m-0">{{ v.state_id.$errors[0]?.$message }}</p>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="">City</label>
            <input type="text" class="form-control"
            :class="{'is-invalid': v.city.$error}"
              v-model="v.city.$model"
            />
            <p class="invalid-feedback d-block m-0">{{ v.city.$errors[0]?.$message }}</p>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label for="">Address</label>
            <input type="text" class="form-control"
              :class="{'is-invalid': v.address.$error}"
                v-model="v.address.$model"
              />
            <p class="invalid-feedback d-block m-0">{{ v.address.$errors[0]?.$message }}</p>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <button type="button" class="btn btn-secondary" @click="$emit('close')">Close</button>
      <button type="submit" class="btn btn-primary" @click="handleSubmit">Save</button>
    </template>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog'
import { reactive } from '@vue/reactivity'

import { required, email, helpers } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

import PropertyService from '../../../services/property.service'
import DropdownStates from '../../../components/dropdowns/DropdownStates.vue'

export default {
  components: { Dialog, DropdownStates },
  props: {
    showModal: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const propertyForm = reactive({
      name: '',
      description: '',
      mls: '',
      address: '',
      city: '',
      state: {},
      state_id: undefined,
      status_id: 2
    })

    //Validation
    const rules = {
      name: { required },
      address: { required },
      city: { required },
      state_id: { required: helpers.withMessage('Property is required', (value) => value !== undefined) },
    }

    const v = useVuelidate(rules, propertyForm)

    const reset = () => {
      propertyForm.name = ''
      propertyForm.address = ''
      propertyForm.city = ''
      propertyForm.state = {}
      propertyForm.state_id = undefined
      v.value.$reset()
    }

    const selectState = (state) => {
      propertyForm.state = state
      propertyForm.state_id = state.id
    }

    const handleSubmit = async () => {
      const isValid = await v.value.$validate()
      if (!isValid) return
      try {
        const property = { ...propertyForm }

        const newProperty = await PropertyService.createProperty(property)
        reset()
        emit('save', newProperty)
      } catch (error) {
        console.log(error)
        emit('error')
      }
    }

    return { selectState, handleSubmit, propertyForm, v }
  },
}
</script>

<style lang="scss" scoped>
fieldset {
  border: 1px solid #b6b6b6;
  legend {
    font-size: 1rem;
  }
}
</style>
